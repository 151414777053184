import { template as template_b679481fd5d1475d9fcf378018500cef } from "@ember/template-compiler";
const FKText = template_b679481fd5d1475d9fcf378018500cef(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
