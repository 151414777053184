import { template as template_f1504434b2254d07a5eeabc2a9d38773 } from "@ember/template-compiler";
const WelcomeHeader = template_f1504434b2254d07a5eeabc2a9d38773(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
